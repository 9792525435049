<template>
  <div class="container pb-12">
    <div class="mb-5">
      <p class="text-4xl text-white font-bold">Registrar</p>
      <p class="text-8xl title-color font-bold">Música</p>
    </div>

    <form action="" class="requires-validation" novalidate id="formRegistro">
      <div class="form-group grid gap-6 grid-cols-1 mb-6 md:grid-cols-2">
        <InputRegistro
          :title="'Título da Música (*)'"
          v-model="form.name"
          class="w-75"
          :required="true"
          id="tituloMusica"
        />

        <div>
          <label
            for="files"
            class="
              btn-upload btn-arquivo-upload
              text-6xl
              font-semibold
              text-center
            "
            >Abrir arquivo</label
          >
          <input
            type="file"
            class="form-control"
            name="files"
            id="files"
            @change="onFileChange"
            @click="clearFile"
            required
            ref="files"
            accept=".mp3, .wav, .pdf"
          />
          <div v-for="(file, index) in files" :key="index">
            <ArquivosComponent :nomeArquivo="file.name" :tamanhoArquivo="file.size" :id="index" @clicked="removerItem"/>
          </div>
          <span class="text-gray-400" >Arquivos obrigatórios: </span>
          <span class="text-white font-semibold" >
            .mp3, .wav ou .pdf</span
          >
          <div class="invalid-feedback">Por favor, selecione um arquivo.</div>
        </div>
      </div>

      <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-2 mt-5 md:mt-0">
        <div class="flex items-center">
          <input
            type="radio"
            :value="compositores"
            v-model="compositores"
            name="compositores"
            id="compositores"
            class="w-5 h-5"
          />
          <span
            for="compositores"
            class="ml-2 w-full font-medium bg-transparent text-white"
            >Compositores</span
          >
        </div>
                <div class="" >
          <label
            for="arquivosOpcionais"
            class="btn btn-primary text-black font-bold"
            >Arquivos Opcionais</label
          >
          <input
            type="file"
            multiple
            class="form-control"
            name="arquivosOpcionais"
            id="arquivosOpcionais"
            @change="onOptionalFilesChange"
            ref="arquivosOpcionais"
            accept=".txt, .doc, .docx, .jpg, .pdf, .mp4, .jpge, .png"
          />
          <div v-for="(arquivo, index) in additional_files" :key="index">
            <!-- <span class="text-white">{{ arquivo.name }} -</span>
            <span class="text-green"> {{ arquivo.size + " Bytes" }}</span> -->
            <ArquivosComponent :nomeArquivo="arquivo.name" :tamanhoArquivo="arquivo.size" :id="index" @clicked="removerItems" class="ml-2 mb-3"/>
          </div>
          <div>
          <span class="text-gray-400">Exemplo: </span>
          <span class="text-white font-semibold">
            .txt, .pdf, .doc, partitura, rascunho original</span
          >
          </div>
        </div>
      </div>

      <div>
        <!-- componente de compositores -->
        <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-2 " >
          <div class="d-flex">
            <InputRegistro
              :title="'Nome (*)'"
              v-model="form.composers"
              class="w-100"
              id="primeiroCompositor"
              :required="true"
            />
          </div>
          <div class="d-flex">
            <InputRegistro
            :title="'CPF (*)'"
            :class="'w-100 '"
            :required="true"
            :subtitle="' Clique em + para adicionar outros compositores!'"
            v-model="cpfPrimeiroComp"
            />
            <InputRegistro
            v-if="numCompositores>0"
            :title="'%'"
            :required="true"
            class="w-28"
            v-model="percentPrimeiroComp"
            />
            <button type="button" class="btn btn-primary " style="height:3.7em" @click="addCompositor">+</button>
          </div>
          </div>
          
          <div v-if="numCompositores>0" class="mb-6">
            <div v-for="(nomeCompositor, index) in nomesCompositores" :key="index" class="mb-2 ">
                <hr class="text-white mb-6">
              <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-2">
                <div class="d-flex ">
                <InputRegistro
                class="w-100"
                :title="'Nome do '+ ( index + 2 ) +'° compositor'"
                :required="true"
                v-model="nomeCompositor.nome"
                :id="'listCompositor' + ( index + 2)"
                />
              </div>
              <div class="d-flex">
                  <InputRegistro
                  :title="'CPF (*)'"
                  v-model="nomeCompositor.cpf"
                  :required="true"
                  class="w-100"
                  />
                  <InputRegistro
                  :title="'%'"
                  class="w-28"
                  :required="true"
                  v-model="nomeCompositor.percent"
                  />
                  <button type="button" class="btn btn-danger" style="height:3.7em" @click="removeCompositor">-</button>
              </div>
            </div>
            </div>
            <p :class="totalPorcentagem">O total da porcentagem é: {{ porcentagemComposicao }} 
              <i class="fas fa-exclamation-triangle text-yellow" v-if="porcentagemComposicao!=100"></i>
              <i class="fa-solid fa-square-check " style="color:#01e769;" v-else></i>
            </p>
            <hr class="text-white mt-6">
            
          </div>
      </div>

      <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-1">
          <InputRegistro
          :title="'Endereço'"
          v-model="form.address"
          :subtitle="'Exemplo: Rua Luiz Quirino Gomes, n° 71 - João Pessoa/PB - CEP 58084024'"
        />
          
      </div>

      <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-2">
        <InputRegistro v-model="form.style" :title="'Estilo'" />
        <InputRegistro v-model="form.language" :title="'Idioma'" />
      </div>

      <!-- <div class="grid gap-6 grid-cols-1 mb-6">
        <InputRegistro v-model="form.interpreter" :title="'Interprete'" :required="true" />
      </div> -->

      <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-1">
        <TextAreaRegistro
          v-model="form.lyrics"
          :title="'Letra da Música'"
        />
      </div>

      <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-1">
        <TextAreaRegistro
          v-model="form.additional"
          :title="'Informações Adicionais'"
        />
      </div>

      <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-1">
        <div class="form-check form-switch">
          <input
            v-model="termosUso"
            name="termosUso"
            :value="termosUso"
            class="form-check-input"
            type="checkbox"
            role="switch"
            required
            id="switchTermosDeUso"
          />
          <span class="text-gray-300"> Eu aceito os </span>
          <a
            class="text-green"
            target="_blank"
            href="https://ichello.com.br/termos-de-uso/"
            >Termos de Uso.</a
          >
          <div class="invalid-feedback">Você precisa concordar com os Termos de Uso.</div>
        </div>
        
      </div>
    <div class="grid gap-6 grid-cols-1 mb-6 ">
      <button class="btn btn-block btn-continuar" @click="salvar()" >
        Registre agora
      </button>
    </div>
<!-- 
      <div class="grid gap-6 grid-cols-1 mb-6 md:grid-cols-1">
        <button class="btn btn-block btn-continuar" @click.prevent="callAction()" v-if="!sending">
          {{ !asset ? "Registre agora" : "Pague agora"}}
        </button>
        <div class="py-11 flex justify-center" v-else>
            <Loading />
        </div>
      </div> -->
    </form>
    <Uploading v-if="uploading"/>
    <ModalPix :capa="asset.thumbnail" :title="asset.name" :asset="asset" @close="redirecionar" :show="m" v-if="m"  :tipo="'registroMusica'"/>
    <BannerImportanciaRegistro />
  </div>
</template>

<script>
import BannerImportanciaRegistro from "../../../components/RegistroMusica/BannerImportanciaRegistro.vue";
import InputRegistro from "../../../components/RegistroMusica/Form/InputRegistro.vue";
import TextAreaRegistro from "../../../components/RegistroMusica/Form/TextAreaRegistro.vue";
import ArquivosComponent from '../../../components/RegistroMusica/Form/ArquivosComponent.vue';
import ModalPix from '../../../components/shared/ModalPix.vue';
import Uploading from '../../../components/shared/Uploading.vue';

export default {
  name: "RegistrarMusica",
  data() {
    return {
      form: {
        name: "",
        composers: "",
        // interpreter: "",
        address: "",
        additional: "",
        style: "",
        language: "",
        lyrics: "",
      },
      sending: false,
      files: [],
      additional_files: [],
      termosUso: false,
      compositores: false,
      m: false,
      uploading: false,
      invalidFields: [],
      arquivos: [],
      asset: null,
      numCompositores: 0,
      percentPrimeiroComp: 100,
      nomesCompositores: [],
      cpfPrimeiroComp: ''

    };
  },

  computed: {

    porcentagemComposicao() {
      let total = 0;
      //pro total nao mostrar NaN
      this.percentPrimeiroComp == '' ? total=0:total=parseInt(this.percentPrimeiroComp);
      //for percorrendo o array de compositores e retornando a soma de todas as porcentagens
      for (let index = 0; index < this.nomesCompositores.length; index++) {
        if(this.nomesCompositores[index].percent==''){
          total += 0;
        } else {
          total += parseInt(this.nomesCompositores[index].percent);
        }
      }
      return total;
    },
    totalPorcentagem() {
      if(this.porcentagemComposicao!=100) {
        return 'text-danger font-bold'
      } else {
        return 'text-primary font-bold'
      }
    },

  },

  methods: {
    addCompositor() {
      this.nomesCompositores.push({nome: '', cpf: '', percent: ''})
      this.numCompositores++
    },

    removeCompositor() {
      this.nomesCompositores.pop()
      this.numCompositores--;
    },

    validacaoPercentual() {      
      let todosCompositores= '';
      if(this.nomesCompositores.length==0 ){
        if(this.validarCpf(this.cpfPrimeiroComp)) {
          //checar se o cpf do primeiro compositor é válido!
          todosCompositores= `${this.form.composers} ${this.cpfPrimeiroComp} 100%`;  
        } else {
          alert('O CPF do 1° compositor não é válido! Por favor, digite um CPF válido!')
          document.getElementById(`primeiroCompositor`).scrollIntoView({ behavior: "smooth" });
        }     
      } else {

        todosCompositores= `${this.form.composers} ${this.cpfPrimeiroComp} ${this.percentPrimeiroComp}%`;
          for (let index = 0; index < this.nomesCompositores.length; index++) {
            //verifica se não há nenhum campo nulo
            if(this.nomesCompositores[index].nome=='' || 
            this.nomesCompositores[index].cpf=='' || 
            this.nomesCompositores[index].percent=='' || 
            this.nomesCompositores[index].percent==0 ||
            this.form.composers=='' ||
            this.cpfPrimeiroComp=='' ||
            this.percentPrimeiroComp=='' ||
            this.percentPrimeiroComp==0 
            ) {
              //scrolla pro elemento q estiver vazio errado
              document.getElementById(`listCompositor${(index+2)}`).scrollIntoView({ behavior: "smooth" });
              alert('Por favor, digite todos os dados dos compositores')
            } else if(!this.validarCpf(this.nomesCompositores[index].cpf)) {
              //scrolla pro elemento q estiver com cpf errado
              document.getElementById(`listCompositor${(index+2)}`).scrollIntoView({ behavior: "smooth" });
              alert(`O CPF do ${index + 2}° compositor não é valido! Por favor, digite um CPF válido!`)
            } else {
              todosCompositores += (" ???? " + this.nomesCompositores[index].nome + " " + this.nomesCompositores[index].percent + "%")
            }
          }
      }
      return todosCompositores;
    },

    validarCpf(strCPF) {
      var Soma;
      var Resto;
        Soma = 0;
      if (strCPF == "00000000000") return false;

      for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

      Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
        return true;
    },
    
    salvar() {
      this.validar();
      if(
        this.form.name=='' || 
        this.form.composers=='' || 
        this.termosUso==false
        // this.form.interpreter=='' || 
        // this.form.address=='' || 
        // this.form.style=='' || 
        // this.form.language==''  
        // this.form.lyrics=='' 
      ) {
        //faz um scroll para os itens obrigatórios
          document.getElementById('tituloMusica').scrollIntoView({ behavior: "smooth" });
        } else if (this.porcentagemComposicao!=100 && this.nomesCompositores.length>0 ){
          alert("A porcentagem da composição não é 100%. Por favor, confira os dados novamente e corrija os valores de composição!")
          document.getElementById('primeiroCompositor').scrollIntoView({ behavior: "smooth" });
        } else if(!this.validarCpf(this.cpfPrimeiroComp)) {
          //verifica se o primeiro cpf é falso
          alert('O CPF do 1° compositor não é válido! Por favor, digite um CPF válido!')
          document.getElementById(`primeiroCompositor`).scrollIntoView({ behavior: "smooth" });
        } if(this.files[0].size > 30 * 1024 * 1024) {
          alert('O arquivo não pode ser maior que 30MB') 
          document.getElementById(`files`).scrollIntoView({ behavior: "smooth" });
        } else {
          // this.validacaoPercentual()
          this.registrarMusica()
          
      }   
    },
    async registrarMusica() {
     this.uploading = true;
     const { VUE_APP_URL } = process.env;
     let headers = { "Authorization": `Bearer ${window.localStorage.getItem('token')}`, "Accept": "javascript/json" }
      new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('file', this.files[0]);
        formData.append('composers', this.validacaoPercentual());
        formData.append('style', this.form.style);
        formData.append('address', this.form.address);
        formData.append('language', this.form.language);
        formData.append('lyrics', this.form.lyrics);
        formData.append('additional', this.form.additional);
        // formData.append('interpreter', this.form.name);

      // para inserção de arquivos nesse sistema, precisa declarar uma promise
      fetch(VUE_APP_URL + 'api/my-musics', {
                    method: 'POST',
                    headers,
                    body: formData,
                }).then(response => {
                  return resolve(
                    (response.json().then( data => {
                    //aqui é gerada a resposta para ser inserida no botao pix
                    // console.log(data.data)
                    this.asset = data.data
                    // console.log(this.asset)
                  })))
                }).catch(error => {
                  console.log(console.log("erro: " + error))
                  this.sending = false
                  return reject(console.error(error))
                })
      }).then(response => {
        // console.log(this.asset)
        //aqui é quando a promise vai pro status de fullfilled
          if(this.additional_files.length!=0) {
            //verificação para inserir o array de arquivos
            this.registrarListaArquivos()
          } else {
            this.uploading=false;
            this.m=true;
          }
        return console.log(response);
      })
    },

    async registrarListaArquivos() {
      const { VUE_APP_URL } = process.env;
      let headers = { "Authorization": `Bearer ${window.localStorage.getItem('token')}` }
      let filesForm = new FormData();
    for (let index = 0; index < this.additional_files.length; index++) {

      new Promise((resolve, reject) => {
        filesForm.append('file', this.additional_files[index], this.additional_files[0].name)
        fetch(VUE_APP_URL + `api/my-musics/${this.asset.id}/additional`, {
                  method: 'POST',
                  headers,
                  body: filesForm,
              }).then(response => {
                (response.json().then( data => {
                  console.log(data.data)
                }))                
                return resolve(console.log(response))
              }).catch(error => {
                return reject(console.error(error))
              })
      }).then(response => {
        //promise fullfilled
        this.uploading=false;
        this.m=true;
        return console.log(response);
      })
      filesForm.delete('file')
    }
    this.sending = false
    },

    validar() {
        (function () {
        "use strict";
        const forms = document.querySelectorAll(".requires-validation");
        Array.from(forms).forEach(function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
              }  
              event.preventDefault();
              form.classList.add("was-validated");
            },
            false
          );
        });
      })();
    },

    clearFile(e) { 
      e.target.value=null;
    },

    onFileChange() {
      this.files = this.$refs.files.files;
    },
    onOptionalFilesChange() {
      let uploadedFiles = this.$refs.arquivosOpcionais.files;
      // console.log(uploadedFiles)
      for(var i = 0; i < uploadedFiles.length; i++) {
        this.additional_files.push(uploadedFiles[i]);
      }
      console.log(this.additional_files)
    },

    removerItem() {
      this.files=[]
    },

    removerItems(nomeArquivo) {
      const index = this.additional_files.findIndex(item => item.name == nomeArquivo)
      this.additional_files.splice(index, 1)
    },

    clearError() {
      this.$refs.invalidFields.reset();
    },
    redirecionar () {
      this.m = false;
      // this.$router.push({ name: 'Login', query: { redirect: '/registrodemusicas/cadastrar' } });
      this.$router.push({ name:'MyMusics'})
    }
  },

  components: {
    BannerImportanciaRegistro,
    InputRegistro,
    TextAreaRegistro,
    ArquivosComponent,
    ModalPix,
    Uploading,

  },
};
</script>

<style scoped>
.title-color {
  background-image: linear-gradient(279.49deg, #06c6ed 30.62%, #f328cf 75.48%);
  width: fit-content;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

input[type="radio"]:checked {
  accent-color: #01e769;
}

input[type="file"] {
  display: none;
}

a {
  text-decoration: underline !important;
}

a:hover {
  filter: brightness(150%);
}

/* Aparência que terá o seletor de arquivo */
.btn-arquivo-upload {
  border-radius: 5px;
  color: #fff !important;
  padding: 6px 20px;
  text-indent: 0%;
  position: relative;
}

.btn-upload {
  width: 100%;
  height: 2em;
  display: flex;
  line-height: 1.5em;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-image: linear-gradient(279.49deg, #06c6ed 30.62%, #f328cf 75.48%);
}

.btn-continuar {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2em;
  border-radius: 10px !important;
}

.form-check-input:checked {
    background-color: #01e769;
    border-color: #01e769;
}

@media (max-width: 700px) {
  #tituloMusica {
    width: 100%;
  }

  .btn-arquivo-upload {
    font-size: 3em;
    padding: 0;
    line-height: 1em;
  }

  .cpfInvalido {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
}
</style>